import type {
	LineItem,
	PriceCalculator,
	Location,
	Order,
	ProductsSpecsByOrder,
	ProductSpec
} from 'types/graphql/app/api/__generated__/graphql'
import { ProductSpecs } from 'types/ProductSpecs.type'

export const RUSH_FEE_PERCENT = 10

export type PriceCalculatorTypes =
	| 'Athletic Style Hem Tag'
	| 'Burnout Ink'
	| 'Custom Hem Tags'
	| 'Custom Tag'
	| 'Custom Tag (heat transfer)'
	| 'Custom Tag (screen print)'
	| 'Foil'
	| 'Fold'
	| 'Fold & Polybag'
	| 'Fold & Polybag (with size sticker)'
	| 'Inside-Out Print'
	| 'Long Sleeve Print'
	| 'Over Tape Neck Label'
	| 'Over-The-Zipper Print'
	| 'Plastic Fastener Hang Tag'
	| 'Printing'
	| 'Puff Ink'
	| 'Roll'
	| 'Roll & Rubber Band'
	| 'Round Size Sticker'
	| 'Safety Pin Hang Tag'
	| 'Sew In Hem Tag'
	| 'Shrink Wrap'
	| 'Smooth Seam Setup'
	| 'Tag Removal (woven labels)'
	| 'UPC Sticker'
	| 'Under Tape Neck Label'
	| 'Wrap Around Size Sticker'

export const getPriceCalculatorForQuantity = (
	orderQuantity: number,
	priceCalculators: Array<any>,
	priceCalculatorType: PriceCalculatorTypes | string
) => {
	if (!priceCalculators) {
		return null
	}

	return priceCalculators?.find((calculator, index) => {
		// if the order quantity is 0, return the first price calculator of the type
		if (orderQuantity === 0) {
			return calculator.description === priceCalculatorType
		}

		// if it is not the last entry in the array
		if (
			!priceCalculators[index + 1] ||
			priceCalculators[index + 1].description !== priceCalculatorType
		) {
			return calculator.description === priceCalculatorType && orderQuantity >= calculator.quantity
		}

		return (
			calculator.description === priceCalculatorType &&
			orderQuantity >= calculator.quantity &&
			orderQuantity < priceCalculators[index + 1].quantity
		)
	})
}

const sumProduct = (width: number, height: number) => width * height

const calculateScreenPrintingPrice = (
	markupPrice: number,
	firstLocation: number,
	numberOfLocations: number,
	extraLocations: number,
	numberOfColors: number,
	extraColors: number,
	skipFirstLocation = false
) => {
	// 	Printing = FirstLocation__c + (numberOfLocations - 1) * AdditionalLocations__c + (numberOfColors - numberOfLocations) * AdditionalColors__c

	//  		FistLocation__c = 4.84
	// 			AdditonalLocations__c = 2.48
	// 			AdditionalColors__c = 1.25

	// 			numberOfLocations = 1
	// 			numberOfColors = 1
	//  			Printing = 4.84 + (abs(1 - 1) * 2.48) + (abs(1 - 1) * 1.25) = 4.84 + 0 + 0 = 4.84
	// 			Calculated Price = Markup price + Printing = 5.52 + 4.84 = 10.36

	// 			numberOfLocations = 1
	// 			numberOfColors = 2
	//  			Printing = 4.84 + (abs(1 - 1) * 2.48) + (abs(1 - 2) * 1.25) = 4.84 + 0 + 1.25 = 6.09
	// 			Calculated Price = Markup price + Printing = 5.52 + 6.09 = 11.61

	// 			numberOfLocations = 2
	// 			numberOfColors = 2
	//  			Printing = 4.84 + (abs(1 - 2) * 2.48) + (abs(2 - 2) * 1.25) = 4.84 + 2.48 + 0 = 7.32
	// 			Calculated Price = Markup price + Printing = 5.52 + 7.32 = 12.84

	// 			numberOfLocations = 2
	// 			numberOfColors = 4
	//  			Printing = 4.84 + (abs(1 - 2) * 2.48) + (abs(2 - 4) * 1.25) = 4.84 + 2.48 + 2.5 = 9.82
	// 			Calculated Price = Markup price + Printing = 5.52 + 9.82 = 15.34

	// 			numberOfLocations = 2
	// 			numberOfColors = 5
	//  			Printing = 4.84 + (abs(1 - 2) * 2.48) + (abs(2 - 5) * 1.25) = 4.84 + 2.48 + 3.75 = 11.07
	// 			Calculated Price = Markup price + Printing = 5.52 + 11.07 = 16.59

	// 			unit cost = 6.49
	//  		markup = 100%
	// 				Markup price = 6.49 * (1 + 1) = 6.49 * 2 = 12.98
	//
	//  		FistLocation__c = 35.09
	// 			AdditonalLocations__c = 35.088
	// 			AdditionalColors__c = 35.09
	// 			numberOfLocations = 2
	// 			numberOfColors = 10
	//  			Printing = 35.09 + (abs(1 - 2) * 35.088) + (abs(2 - 10) * 35.09) = 35.09 + 35.088 + 280.72 = 350.898
	// 			Calculated Price = Markup price + Printing = 12.98 + 350.898 = 363.878

	return (
		markupPrice +
		(skipFirstLocation ? 0 : firstLocation) +
		(numberOfLocations - 1) * extraLocations +
		(numberOfColors - numberOfLocations) * extraColors
	)
}

const calculateEmbroideryPrice = (
	markupPrice: number,
	firstLocation: number,
	numberOfLocations: number,
	extraLocations: number,
	numberOfStitches: number,
	priceCalculator: PriceCalculator,
	skipFirstLocation = false
) => {
	// Embroidery = FirstLocation__c + (numberOfLocations > 1 ? (numberOfLocations - 1) * AdditionalLocations__c : 0 ) + (NumberOfStitches > (NumberOfLocations * IncludedStitches) ? ((NumberOfStitches - NumberOfLocations * IncludedStitches / 1000) * AdditionalStitches) : 0)

	// 			FirstLocation = 6.67
	// 			AdditionalLocations = 5.67
	// 			AdditionalStitches = 0.8
	// 			NumberOfStitches = 6500
	// 			IncludedStitches = 5000
	// 			numberOfLocations = 1

	// 			Embroidery = 6.67 + (1 > 1 ? (1 - 1) * 5.67 : 0) + (6500 > (1 * 5000) ? ((6500 - 1 * 5000) / 1000) * 0.8 : 0) = 6.67 + 0 + 0.8 = 7.47

	// 			FirstLocation = 6.67
	// 			AdditionalLocations = 5.67
	// 			AdditionalStitches = 0.8
	// 			NumberOfStitches = 12500
	// 			IncludedStitches = 5000
	// 			numberOfLocations = 2

	// 			Embroidery = 6.67 + (2 - 1) * 5.67 + (12500 - 2 * 5000) / 1000 * 0.8 = 6.67 + 5.67 + 2 = 14.34

	// 			FirstLocation__c = 6.67
	// 			AdditionalLocations__c = 5.67
	// 			NumberOfStitches = 19900
	// 			IncludedStitches = 5000
	// 			AdditionalStitches = 0.8
	// 			numberOfLocations = 3

	// 			Embroidery = 6.67 + (3 - 1) * 5.67 + (19900 - 3 * 5000) / 1000 * 0.8 = 6.67 + 11.34 + 0.8 = 18.81

	return (
		markupPrice +
		(skipFirstLocation ? 0 : firstLocation) +
		(numberOfLocations > 1 ? (numberOfLocations - 1) * extraLocations : 0) +
		(numberOfStitches && numberOfStitches > numberOfLocations * priceCalculator?.includedStitchCount
			? ((numberOfStitches - numberOfLocations * priceCalculator?.includedStitchCount) / 1000) *
			  priceCalculator?.additionalStitches
			: 0)
	)
}

const calculateTransferPrice = (
	markupPrice: number,
	firstLocation: number,
	numberOfLocations: number,
	extraLocations: number,
	orderLocations: Pick<
		Location,
		'recordType' | 'location' | 'numColors' | 'numberOfStitches' | 'imageWidth' | 'imageHeight'
	>[],
	priceCalculator: PriceCalculator,
	skipFirstLocation = false
) => {
	// Transfer = FirstLocation__c + (numberOfLocations > 1 ? (numberOfLocations - 1) * AdditionalLocations__c : 0) + (sumProduct(locationsWidth, locationsHeight) > (numberOfLocations * IncludedSquareInches) ? (sumProd(locationsWidth, locationsHeight) - numberOfLocations) * IncludedSquareInches) * AdditionalSquareInches : 0))

	// 			FirstLocation = 6.672
	// 			AdditionalLocations = 5.67
	// 			AdditionalSquareInches = 0.05
	// 			IncludedSquareInches = 16
	// 			location1Width = 4
	// 			location1Height = 5
	// 			numberOfLocations = 1

	// Transfer = 6.67 + (1 > 1 ? (1 - 1) * 5.67 : 0) + (4 * 5 > (1 * 16) ? (4 * 5 - (1 * 16)) * 0.05 : 0) = 6.67 + 0 + 0.2 = 6.87

	// 			FirstLocation = 6.67
	// 			AdditionalLocations = 5.67
	// 			AdditionalSquareInches = 0.05
	// 			IncludedSquareInches = 16
	// 			location1Width = 4
	// 			location1Height = 6
	// 			location2Width = 6
	// 			location2Height = 4
	// 			numberOfLocations = 2

	// Transfer = 6.67 + (2 - 1) * 5.67 + ((6 * 4) + (6 * 4) > (2 * 16) ? ((6 * 4) + (6 * 4) - (2 * 16)) * 0.05 : 0) = 6.67 + 5.67 + 0.8 = 13.34

	// 			FirstLocation__c = 6.67
	// 			AdditionalLocations__c = 5.67
	// 			AdditionalSquareInches = 0.05
	// 			IncludedSquareInches = 16
	// 			numberOfLocations = 2
	// 			location1Width = 10
	// 			location1Height = 12
	// 			location2Width = 4
	// 			location2Height = 4

	// Transfer = 6.67 + (2 - 1) * 5.67 + ((10 * 12) + (4 * 4) > (2 * 16) ? ((10 * 12) + (4 * 4) - (2 * 16)) * 0.05 : 0) = 6.67 + 5.67 + 5

	// 			FirstLocation__c = 6.67
	// 			AdditionalLocations__c = 5.67
	// 			AdditionalSquareInches = 0.05
	// 			IncludedSquareInches = 16
	// 			numberOfLocations = 3
	// 			location1Width = 3
	// 			location1Height = 3
	// 			location2Width = 4
	// 			location2Height = 4
	// 			location3Width = 10
	// 			location3Height = 12

	// Transfer = 6.67 + (3 - 1) * 5.67 + ((3 * 3) + (4 * 4) + (10 * 12) > (3 * 16) ? ((3 * 3) + (4 * 4) + (10 * 12) - (3 * 16)) * 0.05 : 0) = 6.67 + 11.34 + 4.85 = 22.86

	let totalSquareInches = 0
	orderLocations.forEach((location) => {
		if (location.imageWidth && location.imageHeight && location.recordType.name === 'Transfer') {
			totalSquareInches += sumProduct(
				parseFloat(location.imageWidth),
				parseFloat(location.imageHeight)
			)
		}
	})

	const includedSquareInches = priceCalculator?.includedSquareInches * numberOfLocations

	return (
		markupPrice +
		(skipFirstLocation ? 0 : firstLocation) +
		(numberOfLocations > 1 ? (numberOfLocations - 1) * extraLocations : 0) +
		(totalSquareInches > includedSquareInches
			? (totalSquareInches - includedSquareInches) * priceCalculator?.additionalSquareInches
			: 0)
	)
}

const calculatePrice = (
	priceCalculatorForQuantity: PriceCalculator,
	orderLocations: Pick<Location, 'recordType' | 'location' | 'numColors' | 'numberOfStitches'>[],
	hasScreen: boolean,
	hasEmbroidery: boolean,
	hasTransfer: boolean,
	markupPrice: number,
	firstLocation: number,
	numberOfLocations: number,
	extraLocations: number,
	numberOfColors: number,
	extraColors: number,
	numberOfStitches: number
) => {
	let price = 0
	if (hasScreen && !hasEmbroidery && !hasTransfer) {
		price = calculateScreenPrintingPrice(
			markupPrice,
			firstLocation,
			numberOfLocations,
			extraLocations,
			numberOfColors,
			extraColors
		)
	} else if (!hasScreen && hasEmbroidery && !hasTransfer) {
		price = calculateEmbroideryPrice(
			markupPrice,
			firstLocation,
			numberOfLocations,
			extraLocations,
			numberOfStitches,
			priceCalculatorForQuantity
		)
	} else if (!hasScreen && !hasEmbroidery && hasTransfer) {
		price = calculateTransferPrice(
			markupPrice,
			firstLocation,
			numberOfLocations,
			extraLocations,
			orderLocations,
			priceCalculatorForQuantity
		)
	} else {
		// Handle combinations by adding the relevant prices
		// Only apply firstLocation fee once
		let firstLocationApplied = false

		if (hasScreen) {
			price += calculateScreenPrintingPrice(
				markupPrice,
				firstLocation,
				numberOfLocations,
				extraLocations,
				numberOfColors,
				extraColors,
				firstLocationApplied
			)
			firstLocationApplied = true
		}
		if (hasEmbroidery) {
			price += calculateEmbroideryPrice(
				markupPrice,
				firstLocation,
				numberOfLocations,
				extraLocations,
				numberOfStitches,
				priceCalculatorForQuantity,
				firstLocationApplied
			)
			firstLocationApplied = true
		}
		if (hasTransfer) {
			price += calculateTransferPrice(
				markupPrice,
				firstLocation,
				numberOfLocations,
				extraLocations,
				orderLocations,
				priceCalculatorForQuantity,
				firstLocationApplied
			)
			firstLocationApplied = true
		}
	}

	return price
}

export const calculatePriceForBlankProduct = (
	priceCalculatorForQuantity: PriceCalculator,
	lineItem: Pick<LineItem, 'quantity' | 'unitCost' | 'manualPrice' | 'product' | 'calculatedPrice'>,
	orderLocations: Pick<Location, 'recordType' | 'location' | 'numColors' | 'numberOfStitches'>[],
	originalOrder?: Order & {
		originalPriceCalculator: PriceCalculator
	}
) => {
	if (!priceCalculatorForQuantity) {
		return {
			price: 0,
			calculatedPrice: 0
		}
	}

	// Unit_Cost__c * (1 + priceCalculatorQuantities__c.Markup__c) + FirstLocation__c + AdditionalLocations__c + AdditionalColors__c
	// Pricing = Markup + Printing

	//  1. how many units are in the order (priceCalculatorForQuantity)
	//  2. unit cost = comes from the line item
	const { unitCost } = lineItem

	//  3. Get the the calculator according to the total of items in the order

	//  4. Get the markup from the calculator record
	const { markup, firstLocation, extraLocations, extraColors } = priceCalculatorForQuantity

	//  5. Markup price = unit cost * (1 + markup)
	//  		unit cost = 4.60
	//  		markup = 20%
	// 		Markup price = 4.60 * (1 + 0.2) = 4.60 * 1.2 = 5.52
	const markupPrice = unitCost * (1 + markup / 100)

	//  6. Get the number of locations in the order from locations, WHERE record type = Screen AND location !== Tag
	const numberOfLocations = orderLocations.filter(
		(location) =>
			(location?.recordType?.name === 'Screen' ||
				location?.recordType?.name === 'DTG' ||
				location?.recordType?.name === 'Hybrid' ||
				location?.recordType?.name === 'Embroidery' ||
				location?.recordType?.name === 'Transfer') &&
			location?.location !== 'Tag'
	).length

	//  7. Sum up the number of colors from the locations
	let numberOfColors = 0
	let numberOfStitches = 0
	let locationsType = [
		{
			hasScreen: false
		},
		{
			hasEmbroidery: false
		},
		{
			hasTransfer: false
		}
	]

	if (orderLocations.length === 0) {
		locationsType[0].hasScreen = true
	}

	orderLocations.forEach((location) => {
		if (
			(location?.recordType?.name === 'Screen' ||
				location?.recordType?.name === 'DTG' ||
				location?.recordType?.name === 'Hybrid') &&
			location?.location !== 'Tag'
		) {
			numberOfColors += location.numColors
			locationsType[0].hasScreen = true
		}

		if (
			(location?.recordType?.name === 'Embroidery' ||
				location?.recordType?.name === 'DTG' ||
				location?.recordType?.name === 'Hybrid') &&
			location?.location !== 'Tag'
		) {
			numberOfStitches += location.numberOfStitches
			locationsType[1].hasEmbroidery = true
		}

		if (
			(location?.recordType?.name === 'Transfer' ||
				location?.recordType?.name === 'DTG' ||
				location?.recordType?.name === 'Hybrid') &&
			location?.location !== 'Tag'
		) {
			locationsType[2].hasTransfer = true
		}
	})

	//  8. We have 3 types of records: Printing, Embroidery and Transfer

	const { hasScreen } = locationsType[0]
	const { hasEmbroidery } = locationsType[1]
	const { hasTransfer } = locationsType[2]

	const newCalculatedPrice =
		calculatePrice(
			priceCalculatorForQuantity,
			orderLocations,
			hasScreen,
			hasEmbroidery,
			hasTransfer,
			markupPrice,
			firstLocation,
			numberOfLocations,
			extraLocations,
			numberOfColors,
			extraColors,
			numberOfStitches
		) ?? 0

	// 9. If it has manual price, calculate the old price percentage discount for the new price
	let calculatedPrice = 0
	let manualMarginPercent = 0

	if (lineItem.manualPrice && lineItem.manualPrice >= 0) {
		const oldMarkupPrice = unitCost * (1 + originalOrder?.originalPriceCalculator?.markup / 100)
		// Calculate the old price
		calculatedPrice =
			calculatePrice(
				originalOrder?.originalPriceCalculator,
				orderLocations,
				hasScreen,
				hasEmbroidery,
				hasTransfer,
				oldMarkupPrice,
				originalOrder?.originalPriceCalculator?.firstLocation,
				numberOfLocations,
				originalOrder?.originalPriceCalculator?.extraLocations,
				numberOfColors,
				originalOrder?.originalPriceCalculator?.extraColors,
				numberOfStitches
			) ?? 0

		manualMarginPercent = lineItem.manualPrice
			? (lineItem.manualPrice - lineItem.unitCost * 1.05) /
			  (calculatedPrice - lineItem.unitCost * 1.05)
			: 0
	}

	const newCalculatedPriceFixed = newCalculatedPrice.toFixed(2)
	console.log(lineItem, newCalculatedPriceFixed)

	// 10. Return the new manual price and the calculated price
	return {
		price: Number(
			lineItem.manualPrice && lineItem.manualPrice >= 0
				? manualMarginPercent > 0
					? priceCalculatorForQuantity === originalOrder?.originalPriceCalculator
						? lineItem.manualPrice
						: newCalculatedPrice +
						  (newCalculatedPrice - lineItem.unitCost * 1.05) * (manualMarginPercent - 1)
					: newCalculatedPriceFixed
				: lineItem.quantity === 0 && originalOrder
				? lineItem.calculatedPrice ?? newCalculatedPriceFixed
				: newCalculatedPriceFixed
		),

		calculatedPrice: Number(newCalculatedPriceFixed)
	}
}

export const calculateEstimatedCosts = (
	lineItems: LineItem[] | ProductsSpecsByOrder['additionalCharges'],
	originalOrder?: Order
) => {
	let Est_COGS_Shirts_Shipping_Service = 0
	lineItems?.forEach((lineItem) => {
		if (lineItem.allowPurchase && lineItem.product.recordTypeName === 'Blank Product') {
			Est_COGS_Shirts_Shipping_Service +=
				lineItem.unitCost ?? 0 * Math.round(lineItem.quantity * 1.05)
		} else if (lineItem.product.recordTypeName === 'Service') {
			Est_COGS_Shirts_Shipping_Service += lineItem.price ?? 0 * Math.round(lineItem.quantity * 1.05)
		}
	})

	let Est_COGS_Shipping__c = 0

	originalOrder.fulfillments.forEach((fulfillment) => {
		Est_COGS_Shipping__c += fulfillment.negotiatedRate ?? 0
	})

	return {
		Est_COGS_Shirts_Shipping_Service: Est_COGS_Shirts_Shipping_Service ?? 0,
		Est_COGS_Shipping__c: Est_COGS_Shipping__c ?? 0
	}
}

export const calculateAllLineItemsEstimatedCosts = (
	productSpecs: ProductSpec[],
	additionalCharges: ProductsSpecsByOrder['additionalCharges'],
	originalOrder: Order
) => {
	let estimatedCosts = {
		Est_COGS_Shirts_Shipping_Service: 0,
		Est_COGS_Shipping__c: 0
	}
	productSpecs?.map((productSpec) => {
		productSpec.colors.map((color) => {
			estimatedCosts = calculateEstimatedCosts(color.lineItems, originalOrder)
		})
	})

	const additionalChargesEstimatedCosts = calculateEstimatedCosts(additionalCharges, originalOrder)

	return {
		Est_COGS_Shirts_Shipping_Service:
			estimatedCosts.Est_COGS_Shirts_Shipping_Service +
			additionalChargesEstimatedCosts.Est_COGS_Shirts_Shipping_Service,
		Est_COGS_Shipping__c:
			estimatedCosts.Est_COGS_Shipping__c + additionalChargesEstimatedCosts.Est_COGS_Shipping__c
	}
}

export const calculateRushFee = (
	productSpecs: ProductSpec[] | ProductSpecs | any,
	additionalCharges: ProductsSpecsByOrder['additionalCharges'] | ProductSpecs | any,
	order: Order,
	rushPercent: number
) => {
	let rushFee = 0

	if (order?.rushFeeManualPrice !== null && order?.rushFeeManualPrice !== undefined) {
		return order.rushFeeManualPrice
	}

	const lineItems = productSpecs?.flatMap(
		(productSpec) => productSpec.colors?.flatMap((color) => color.lineItems) ?? productSpec
	)

	const rushFeeForLineItems = lineItems?.reduce((acc, lineItem) => {
		return (
			acc +
			((lineItem?.manualPrice ?? lineItem?.calculatedPrice ?? lineItem?.price) -
				lineItem?.unitCost * 1.05) *
				lineItem?.quantity
		)
	}, 0)

	const rushFeeForAdditionalCharges = additionalCharges?.reduce((acc, additionalCharge) => {
		return (
			acc +
			(additionalCharge?.price - additionalCharge?.unitCost * 1.05) * additionalCharge?.quantity
		)
	}, 0)

	rushFee =
		(Math.abs(rushFeeForLineItems) + Math.abs(rushFeeForAdditionalCharges)) * (rushPercent / 100)

	return rushFee
}

export const calculateDiscountPrice = (
	order: Order,
	productSpecs: ProductSpec[],
	additionalCharges: ProductsSpecsByOrder['additionalCharges'],
	subtotal: number,
	rushFee: number
) => {
	if (order?.discountManualPrice < 0 || order?.discountManualPrice > 0) {
		return -Math.abs(order?.discountManualPrice)
	}

	const revenueDiscount = order?.revenueDiscount
		? -((subtotal + rushFee) * order?.revenueDiscount) / 100
		: 0

	let grossProfitDiscount = 0

	if (order?.grossProfitDiscount > 0) {
		const lineItems = productSpecs?.flatMap((productSpec) =>
			productSpec.colors?.flatMap((color) => color.lineItems)
		)

		const grossProfitDiscountForLineItems =
			order?.grossProfitDiscount > 0
				? lineItems?.reduce((acc, lineItem) => {
						return (
							acc +
							((lineItem?.manualPrice ?? lineItem?.calculatedPrice ?? lineItem?.price) -
								lineItem?.unitCost * 1.05) *
								lineItem?.quantity
						)
				  }, 0)
				: 0

		const grossProfitDiscountForAdditionalCharges = additionalCharges?.reduce(
			(acc, additionalCharge) => {
				return (
					acc +
					(additionalCharge?.price - additionalCharge?.unitCost * 1.05) * additionalCharge?.quantity
				)
			},
			0
		)

		grossProfitDiscount =
			((grossProfitDiscountForLineItems + grossProfitDiscountForAdditionalCharges + rushFee) *
				order?.grossProfitDiscount) /
			100
	}

	const discountPrice = -Math.abs(revenueDiscount + grossProfitDiscount)

	return discountPrice
}
